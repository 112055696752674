import {
  cache,
  createAsync,
  useIsRouting,
  useSearchParams,
} from "@solidjs/router";
import {
  Accessor,
  createEffect,
  createMemo,
  createResource,
  createSignal,
  For,
  Match,
  onCleanup,
  onMount,
  Show,
  Suspense,
  Switch,
} from "solid-js";
import ClientOnlyComponent, {
  ClientComponent,
} from "~/client_only_components/client_component";
import { BrandCard } from "~/components/brand/brand_card";
import {
  Header,
  isFreshLogin,
  setIsFreshLogin,
} from "~/components/brand_l2/header";
import { Footer } from "~/components/footer";
import {
  CategoryGridCard,
  CategoryStrip,
} from "~/components/home/category_card";
import { HomeRnrHeader } from "~/components/home/home-rnr-header";
import { HomeHubbleHeader } from "~/components/home/home_hubble_header";
import { HorizontalScrollCard } from "~/components/home/horizontal_scroll_card";
import { SectionSeparator } from "~/components/homepage/section_separator";
import { useModal } from "~/components/modal";
import WelcomeMessage from "~/components/welcome-message";
import { RNREvent, rnrEventManager } from "~/data/events";
import {
  getHomePageRouteData,
  HomePageRouteData,
} from "~/server/data/home_page_route_data";
import { QueryParam } from "~/types";
import { getClientIdFromCookie, rehypeUppercaseATags } from "~/utils/common";
import { BrandingRow, HubbleBranding } from "~/widgets/branding";
import { DottedLoader } from "~/widgets/loader";
import { zIndexes } from "~/z_indexes";
import occasionsData from "~/data/groups/occasions.json";
import recipientsData from "~/data/groups/recipients.json";
import SolidMarkdown from "solid-markdown";
import rehypeRaw from "rehype-raw";
import attachPopStateListener from "~/utils/popstate_listener";
import { getCoinsSummary } from "~/server/apis/client_apis";
import QuickCommerceStrip from "~/components/home/quick_commerce_stip";
import { Link, Meta, Title } from "@solidjs/meta";
import { SocialProofing } from "~/components/home/social_proofing";
import { ValueCommunication } from "~/components/home/value_communication";
import { GiftsSection } from "~/components/home/gifts_section";
import { Banners } from "~/widgets/banners";
import { getBanners } from "~/data/banners";
import { Spacer } from "~/widgets/spacer";
import { config, RNR_PAGE_TITLE } from "~/data/config";
import HomeComponentV2 from "~/components/home_v2/home_v2";

const getHomePageRouteData$C = cache(getHomePageRouteData, "landingpage");

export enum SectionType {
  SectionSeparator = "SectionSeparator",
  AllBrands = "AllBrands",
}

export default function Home() {
  const [queryParams] = useSearchParams();
  const isRouting = useIsRouting();
  const { setIsModalOn, updateModalContentGetter, setOuterFrameClass } =
    useModal()!;
  const [showedWelcomeMessage, setShowedWelcomeMessage] = createSignal(false);
  let observerRef: HTMLDivElement | undefined = undefined;

  let observerCategoryGridRef: HTMLDivElement | undefined = undefined;

  const [showCategoryGrid, setShowCategoryGrid] = createSignal(true);
  const [isDefaultCoinBalanceHidden, setIsDefaultCoinBalanceHidden] =
    createSignal(false);
  const [showCategoryList, setShowCategoryList] = createSignal(false);

  const routeData: Accessor<HomePageRouteData | undefined> =
    createAsync<HomePageRouteData>(
      () => {
        return getHomePageRouteData$C({
          clientId: queryParams.clientId,
          clientSecret: queryParams.clientSecret,
          token: queryParams.token,
        });
      },
      {
        deferStream: true,
      }
    );

  const isLoggedIn = createMemo<boolean>(() => {
    return isFreshLogin() ?? false;
  });

  const [latestCoinsBalance, setLatestCoinsBalance] = createSignal<
    number | undefined
  >(routeData()?.coins?.totalAvailable);
  const [refreshLatestBalance, setRefreshLatestBalance] = createSignal(false);

  async function fetchLatestBalance() {
    const response = await getCoinsSummary();
    setLatestCoinsBalance(response.totalAvailable);
  }

  const [banners] = createResource(async () => {
    const bannersList = await getBanners("hubble");

    return bannersList.banners;
  });

  const isClientAxis = createMemo(() => {
    return routeData()?.clientId === config.axisClientId;
  });

  createEffect(async () => {
    if (refreshLatestBalance()) {
      await fetchLatestBalance();
      setRefreshLatestBalance(false);
    }
  });

  onMount(() => {
    if (routeData()?.mode === "rnr") {
      rnrEventManager.setClientId(getClientIdFromCookie() ?? "");
      rnrEventManager.setIdentity(routeData()?.userProfile?.id ?? "");
      rnrEventManager.sendEvent(RNREvent.VISIT_HOME_PAGE);
    }
    attachPopStateListener();
  });

  createEffect(async () => {
    if (isLoggedIn()) {
      await fetchLatestBalance();
    }
  });

  const getCoins = createMemo<number>(() => {
    return latestCoinsBalance() ?? routeData()?.coins?.totalAvailable ?? 0;
  });

  createEffect(() => {
    setIsFreshLogin(routeData()?.isLoggedIn ?? false);

    const searchParams = new URLSearchParams(window.location.search);
    const showDummyPopup = searchParams.get(QueryParam.Popup) == "1";

    if (
      (routeData()?.coins?.newCoinAdditionDetails &&
        routeData()?.coins?.newCoinAdditionDetails?.amount &&
        routeData()?.coins?.newCoinAdditionDetails!.amount! > 0 &&
        !showedWelcomeMessage()) ||
      showDummyPopup
    ) {
      setShowedWelcomeMessage(true);
      setOuterFrameClass("md:w-[380px] md:h-[516px]");
      updateModalContentGetter(() => {
        return (
          <WelcomeMessage
            coinsCredited={
              routeData()?.coins?.newCoinAdditionDetails?.amount ?? 0
            }
            userName={routeData()?.name ?? ""}
            title={
              routeData()?.coins?.newCoinAdditionDetails?.eventDetails.title ??
              "Congratulations"
            }
            subtitle={
              routeData()?.coins?.newCoinAdditionDetails?.eventDetails
                .description ?? ""
            }
            showDummy={showDummyPopup}
          />
        );
      });
      setIsModalOn(true);
    }
  });

  let observer: IntersectionObserver;
  let categoryObserver: IntersectionObserver;

  let exploreBrandsRef: HTMLDivElement | undefined;

  const scrollToElement = () => {
    if (exploreBrandsRef) {
      exploreBrandsRef.scrollIntoView({ behavior: "smooth" });
    }
  };

  onMount(() => {
    observer = new IntersectionObserver((entries) => {
      if (routeData()?.mode == "hubble") {
        setIsDefaultCoinBalanceHidden(true);
        return;
      }
      if (entries[0].isIntersecting) {
        setIsDefaultCoinBalanceHidden(false);
      } else {
        setIsDefaultCoinBalanceHidden(true);
      }
    });

    categoryObserver = new IntersectionObserver((entries) => {
      if (
        entries[0].boundingClientRect.height === 0 &&
        entries[0].boundingClientRect.width === 0
      ) {
        return;
      }
      if (entries[0].isIntersecting) {
        setShowCategoryGrid(true);
      } else {
        setShowCategoryGrid(false);
      }
    });

    onCleanup(() => {
      if (observerRef) {
        observer.unobserve(observerRef);
      }
      if (observerCategoryGridRef) {
        categoryObserver.unobserve(observerCategoryGridRef);
      }
    });
  });

  createEffect(() => {
    if (routeData() && !isRouting()) {
      if (observerRef) {
        observer.observe(observerRef);
      }
      if (observerCategoryGridRef) {
        categoryObserver.observe(observerCategoryGridRef);
      }
    }
  });

  return (
    <Switch>
      <Match when={isClientAxis()}>
        <HomeComponentV2
          isClientAxis={isClientAxis}
          latestCoinsBalance={latestCoinsBalance}
          routeData={routeData}
          setRefreshLatestBalance={setRefreshLatestBalance}
        />
      </Match>
      <Match when={true}>
        <HomeComponentV1 />
      </Match>
    </Switch>
  );

  function HomeComponentV1() {
    return (
      <Suspense
        fallback={
          <div class="flex flex-col items-center justify-center">
            <DottedLoader color="#999" />
            <Show when={routeData()?.mode === "rnr"}>
              <div class="text-mediumBold">Loading your rewards</div>
            </Show>
          </div>
        }
      >
        <Show when={routeData()?.mode === "hubble"}>
          <Meta name="robots" content="follow, index" />
          <Title>{routeData()?.metadata.title}</Title>
          <Link rel="canonical" href={routeData()?.metadata.scripts[0].url} />
          <Meta name="og:type" content="product" />
          <Meta name="og:title" content={routeData()?.metadata.title} />
          <Meta
            name="description"
            content={routeData()?.metadata.description}
          />
          <Meta name="keywords" content={routeData()?.metadata.keywords} />
          <Meta
            name="og:description"
            content={routeData()?.metadata.description}
          />
          {routeData()?.metadata.scripts.map((script: any) => (
            <script
              type="application/ld+json"
              innerHTML={JSON.stringify(script)}
            />
          ))}
        </Show>
        <Show when={routeData()?.mode === "rnr"}>
          <Title>{RNR_PAGE_TITLE}</Title>
        </Show>

        <div>
          <ClientOnlyComponent component={ClientComponent.ModalHost} />
          <Show when={routeData()}>
            <Show when={routeData()?.mode === "hubble"}>
              <QuickCommerceStrip />
            </Show>
            <Header
              headerImageUrl={routeData()?.clientConfig?.clientLogoUrl}
              showActions={!routeData()?.sdkMetaData?.isSDK}
              isLoggedIn={isLoggedIn}
              mode={routeData()?.mode ?? "rnr"}
              coins={getCoins}
              showCoins={isDefaultCoinBalanceHidden}
              setRefreshCoins={setRefreshLatestBalance}
              clientId={routeData()?.clientId}
            />
          </Show>
          <div
            class={`fixed ${isClientAxis() ? "top-20" : "top-[54px]"} w-full overflow-y-visible  sm:hidden`}
            style={{
              "z-index": zIndexes.categoryStrip,
            }}
          >
            <Show
              when={
                !showCategoryGrid() &&
                routeData()?.categories &&
                routeData()?.categories != null &&
                !config.customizedSDKClients.limitedCategoryContent.includes(
                  routeData()?.clientId!
                )
              }
            >
              <CategoryStrip
                categories={routeData()!.categories.data}
                maxCategoriesOnStrip={Math.min(
                  10,
                  routeData()!.categories.data.length
                )}
                scroll={true}
                border={false}
                showCategoryList={showCategoryList}
                setShowCategoryList={setShowCategoryList}
                occasions={occasionsData.occasions}
                recipients={recipientsData.recipients}
              />
            </Show>
          </div>
          <Show
            when={routeData() && !isRouting()}
            fallback={<DottedLoader color="#999" />}
          >
            <Show when={routeData()?.mode === "rnr"}>
              <HomeRnrHeader
                name={routeData()?.name ?? ""}
                coinsAvailable={
                  latestCoinsBalance() ??
                  routeData()?.coins?.totalAvailable ??
                  0
                }
                setRefreshCoins={setRefreshLatestBalance}
                observerRef={observerRef}
                isClientAxis={isClientAxis}
              />
            </Show>
            <Show when={routeData()?.mode === "hubble"}>
              <HomeHubbleHeader scrollToElement={scrollToElement} />
            </Show>
            <div ref={observerRef}></div>

            <Show
              when={
                routeData()?.categories &&
                routeData()?.categories != null &&
                !config.customizedSDKClients.limitedCategoryContent.includes(
                  routeData()?.clientId!
                )
              }
            >
              <div ref={observerCategoryGridRef} class="px-4 pt-5 sm:hidden">
                <CategoryGridCard
                  categories={routeData()?.categories!.data ?? []}
                  showCategoryList={showCategoryList}
                  setShowCategoryList={setShowCategoryList}
                  occasions={occasionsData.occasions}
                  recipients={recipientsData.recipients}
                />
              </div>
            </Show>

            <div class="w-full">
              <div
                class={`sticky ${isClientAxis() ? "top-24" : "top-16"}  hidden bg-baseTertiaryLight pb-3 pt-3 sm:flex`}
                style={{
                  "z-index": zIndexes.categoryStrip,
                }}
              >
                <Show
                  when={
                    routeData()?.categories &&
                    routeData()?.categories != null &&
                    !config.customizedSDKClients.limitedCategoryContent.includes(
                      routeData()?.clientId!
                    )
                  }
                >
                  <CategoryStrip
                    categories={routeData()!.categories.data}
                    maxCategoriesOnStrip={Math.min(
                      10,
                      routeData()!.categories.data.length
                    )}
                    scroll={false}
                    border={true}
                    occasions={occasionsData.occasions}
                    recipients={recipientsData.recipients}
                    showCategoryList={showCategoryList}
                    setShowCategoryList={setShowCategoryList}
                  />
                </Show>
              </div>
              <Show
                when={
                  !config.customizedSDKClients.limitedCategoryContent.includes(
                    routeData()?.clientId!
                  )
                }
              >
                <HorizontalScrollCard
                  title="Popular Brands"
                  brandList={routeData()
                    ?.popularBrands?.data[0].products.slice(0, 12)
                    .map((brand) => ({
                      id: brand.id,
                      brandThumbnailUrl: brand.voucherProduct.iconImageUrl,
                      brandTitle: brand.voucherProduct.title,
                      brandCategory: brand.displayTags[0] || "",
                      brandKey: brand.brandKey,
                      discountPercentage:
                        brand.voucherProduct.discountPercentage,
                      showHubbleCoinReward:
                        brand.voucherProduct.rewardType === "POSTPAID",
                    }))}
                ></HorizontalScrollCard>
              </Show>
              <Show
                when={
                  routeData()?.mode === "hubble" && banners.state == "ready"
                }
              >
                <div class="my-10 md:mx-auto md:max-w-[1136px]">
                  <div class="hidden sm:block">
                    <Banners
                      banners={banners()}
                      showHeader
                      perView={2.5}
                      mode={"hubble"}
                    />
                  </div>
                  <div class="sm:hidden">
                    <Banners
                      banners={banners()}
                      showHeader
                      perView={1}
                      mode={"hubble"}
                    />
                  </div>
                </div>

                {/*<GetToKnowGC />*/}
              </Show>

              <div class="w-full px-0">
                <For each={routeData()?.brandList}>
                  {(section, index) =>
                    "title" in section ? (
                      (section.brandList ?? []) && (
                        <>
                          <SectionSeparator {...section} />
                          <Show
                            when={
                              routeData()?.mode === "hubble" && index() === 2
                            }
                          >
                            <SocialProofing />
                          </Show>
                        </>
                      )
                    ) : (
                      <div
                        class="ml-auto mr-auto py-8 lg:max-w-[1136px] lg:px-0"
                        ref={exploreBrandsRef}
                      >
                        <Show when={section.showAllBrandsTitle}>
                          <div class="flex flex-row items-center px-4">
                            <h2 class="text-nowrap text-bold text-basePrimaryDark lg:text-h4">
                              All brands
                            </h2>
                            <div class="ml-4 h-[1px] w-full bg-baseTertiaryDark"></div>
                          </div>
                        </Show>
                        <div class="noScrollbar mt-5 grid grid-cols-3 gap-6 gap-x-3 gap-y-6 overflow-auto px-4 md:grid-cols-5 md:gap-x-8 lg:grid-cols-6">
                          <For each={section.brands}>
                            {(brand, indexBrandCard) => (
                              <>
                                <BrandCard
                                  imageUrl={brand.voucherProduct.iconImageUrl}
                                  name={brand.voucherProduct.title}
                                  categoryName={brand.displayTags[0] || ""}
                                  brandKey={brand.brandKey}
                                  discountPercentage={
                                    brand.voucherProduct.discountPercentage
                                  }
                                  showHubbleCoinReward={
                                    brand.voucherProduct.rewardType ===
                                    "POSTPAID"
                                  }
                                />
                                <Show
                                  when={
                                    index() === 3 &&
                                    indexBrandCard() === 11 &&
                                    routeData()?.mode === "hubble"
                                  }
                                >
                                  <div class="col-span-full">
                                    <ValueCommunication />
                                  </div>
                                </Show>
                              </>
                            )}
                          </For>
                        </div>
                        <Show
                          when={
                            section.showGiftsSection &&
                            routeData()?.mode === "hubble"
                          }
                        >
                          <GiftsSection />
                        </Show>
                      </div>
                    )
                  }
                </For>
              </div>
            </div>

            <Show
              when={routeData()?.mode === "hubble" && routeData()?.footNotes}
            >
              <div class="mx-auto text-baseDark lg:max-w-[1136px]">
                <SolidMarkdown
                  class="markdown"
                  rehypePlugins={[rehypeUppercaseATags, rehypeRaw] as any}
                >
                  {routeData()?.footNotes.content}
                </SolidMarkdown>
              </div>
            </Show>

            <Spacer height={40}></Spacer>

            <Show when={routeData()?.mode === "rnr"}>
              <HubbleBranding class="hidden w-full py-4 sm:flex" />
              <BrandingRow class="fixed bottom-0 left-0 right-0 items-center sm:hidden" />
            </Show>

            <Show when={routeData()?.mode === "hubble"}>
              <div class="mx-auto lg:max-w-[1136px]">
                <Footer />
              </div>
            </Show>
          </Show>
        </div>
      </Suspense>
    );
  }
}
